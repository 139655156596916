const routes = [
    { path: "/", name: "home", component: () => import("@/views/Dashboard"), meta: { login_required: true } },
    { path: "/settings", name: "settings", component: () => import("@/views/Settings"), meta: { admin_route: true } },
    { path: "/profile", name: "profile", component: () => import("@/views/user/Edit"), meta: { login_required: true } },
    { path: "/users", name: "user.list", component: () => import("@/views/user/List"), meta: { admin_route: true, scroll_position: true } },
    { path: "/user/:id?", name: "user.edit", component: () => import("@/views/user/Edit"), meta: { admin_route: true } },
    { path: "/owner/:id", name: "owner.edit", component: () => import("@/views/owner/Edit"), meta: { login_required: true } }
]

export default routes
